import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import * as moment from 'moment-mini-ts';
import { Config } from '@core/service/config';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-input-date-time',
    templateUrl: 'date-time.component.html',
})
export class DateTimeComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() control: UntypedFormControl | AbstractControl;
    @Input() id: string = '';
    @Input() placeholder: string = '';
    @Input() focus: boolean = false;
    @Input() disabled: boolean = false;

    @Output() enter = new EventEmitter<boolean>();

    @ViewChild('date') elemInput: ElementRef;

    public value: string | null = null;
    public format: string = '';
    public formatBootstrap: string = '';

    private subs$: Subscription;

    constructor(private _config: Config) {}

    ngOnInit() {
        if (this.focus) {
            this.elemInput.nativeElement.focus();
        }

        const configSite = this._config.get('site');
        this.format = configSite.date.full;
        if (this.format.indexOf('mm') >= 0) {
            this.formatBootstrap = this.format
                .replace('mm', 'ii')
                .toLowerCase();
        } else {
            this.formatBootstrap = this.format.toLowerCase();
        }

        this.subs$ = this.control.valueChanges.subscribe((res) => {
            this.value = this.formDateUp(res);
        });
    }

    ngOnDestroy(): void {
        if (this.subs$) {
            this.subs$.unsubscribe();
        }
    }

    formDateUp(_timestamp: number): string {
        if (_timestamp) {
            return moment.unix(_timestamp).format(this.format);
        }
        return '';
    }

    evenEnter() {
        this.enter.emit(true);
    }

    ngAfterViewInit() {
        $.fn.datetimepicker['dates']['ru'] = {
            days: [
                'воскресенье',
                'понедельник',
                'вторник',
                'среда',
                'четверг',
                'пятница',
                'суббота',
            ],
            daysShort: ['вск', 'пнд', 'втр', 'срд', 'чтв', 'птн', 'сбт'],
            daysMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
            months: [
                'Январь',
                'Февраль',
                'Март',
                'Апрель',
                'Май',
                'Июнь',
                'Июль',
                'Август',
                'Сентябрь',
                'Октябрь',
                'Ноябрь',
                'Декабрь',
            ],
            monthsShort: [
                'Янв',
                'Фев',
                'Мар',
                'Апр',
                'Май',
                'Июн',
                'Июл',
                'Авг',
                'Сен',
                'Окт',
                'Ноя',
                'Дек',
            ],
            today: 'Сегодня',
            clear: 'Очистить',
            format: this.formatBootstrap,
            titleFormat: 'MM yyyy',
            weekStart: 1,
            meridiem: '',
        };

        const datepicker = $('.m_datetimepicker');
        datepicker
            .datetimepicker({
                todayHighlight: true,
                timePicker: true,
                timePicker24Hour: true,
                language: 'ru',
                orientation: 'bottom left',
                templates: {
                    leftArrow: '<i class="la la-angle-left"></i>',
                    rightArrow: '<i class="la la-angle-right"></i>',
                },
            })
            .on('changeDate', (e: any) => {
                const date = moment(e.date);
                this.control.setValue(date.utc().unix());
            });
    }
}
