import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-input-wysiwyg',
    templateUrl: 'wysiwyg.component.html',
})
export class WysiwygComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() control: UntypedFormControl | AbstractControl;
    @Input() id: string = '';
    @Input() placeholder: string = '';
    @Input() focus: boolean = false;

    @Output() enter = new EventEmitter();

    // @ViewChild('wysiwyg') elemInput: ElementRef;

    private submit$: Subscription;
    public submit: boolean = false;
    public text: string = '';

    ngAfterViewInit() {
        /* Подключение редактора */
        const summ = $('#' + this.id);
        summ.summernote({
            height: 225,
            popover: {
                image: [],
            },
            toolbar: [
                ['style', ['style', 'bold', 'italic', 'underline', 'clear']],
                ['fontsize', ['fontsize']],
                ['insert', ['table', 'link', 'video', 'picture']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['different', ['codeview', 'help', 'clearAll']],
            ],
            callbacks: {
                onChange: (contents, $editable) => {
                    if (contents) {
                        contents = contents.replace(/(<p><\/p>)/gi, '');
                    }

                    if (!summ.summernote('isEmpty')) {
                        const q = $('<div>').append(contents);
                        const img = q.find('img');
                        img.each((k, v) => {
                            img.eq(k)
                                .removeAttr('style')
                                .attr('loading', 'lazy')
                                .css('width', '100%')
                                .css('height', 'auto');
                        });

                        contents = q.html();
                        this.control.setValue(contents);
                    } else {
                        contents = '';
                        this.control.setValue('');
                    }
                    this.enter.emit(contents);
                    return contents;
                },
                onPaste: function (e) {
                    const bufferText = (
                        (e.originalEvent || e).clipboardData ||
                        window['clipboardData']
                    ).getData('Text');

                    e.preventDefault();

                    const selection = window.getSelection(); // Получаем текущее выделение
                    if (selection.rangeCount > 0) {
                        const range = selection.getRangeAt(0); // Получаем диапазон выделения
                        range.deleteContents(); // Удаляем текущее выделение (если оно есть)

                        // Создаем текстовый узел с новым текстом
                        const textNode = document.createTextNode(bufferText);

                        // Вставляем текстовый узел в текущее положение курсора
                        range.insertNode(textNode);

                        // Перемещаем курсор после вставленного текста
                        range.setStartAfter(textNode);
                        range.setEndAfter(textNode);
                        selection.removeAllRanges();
                        selection.addRange(range);
                    }
                },
            },
            buttons: {
                clearAll: function (context) {
                    const ui: any = $['summernote']['ui'];

                    const button = ui['button']({
                        contents: '<b>X</b>',
                        tooltip: 'Clear All',
                        container: false,
                        click: function () {
                            summ.summernote('code', '');
                            summ.summernote({ focus: true });
                        },
                    });

                    return button['render']();
                },
            },
        });

        summ.summernote('code', this.control.value);
    }

    ngOnInit() {
        if (
            this.control.root &&
            this.control.root.get('submit') instanceof UntypedFormControl
        ) {
            this.submit$ = this.control.root
                .get('submit')
                .valueChanges.subscribe((res) => {
                    this.submit = res;
                });
        }
    }

    ngOnDestroy() {
        if (this.submit$) {
            this.submit$.unsubscribe();
        }
    }

    evenEnter() {
        this.enter.emit(true);
    }
}
